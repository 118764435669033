import { AsyncPipe, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { ConfigurationService } from '@ep/shared';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { getEnvironment } from '@shared/utils/environment';
import { BehaviorSubject, catchError, tap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe, PdfViewerModule],
})
export class PrivacyStatementComponent implements OnInit {
  private _privacyPolicyURI: BehaviorSubject<Uint8Array | undefined> = new BehaviorSubject<Uint8Array | undefined>(
    undefined
  );
  protected readonly privacyPolicyURI = this._privacyPolicyURI
    .asObservable()
    .pipe(filter((value) => value !== undefined));
  private client = inject(HttpClient);
  private apiUrl?: string | null = null;

  constructor(private configurationService: ConfigurationService) {}

  async ngOnInit(): Promise<void> {
    await this.configurationService.load(getEnvironment()).then((applicationConfig) => {
      this.apiUrl = applicationConfig?.serviceApiBaseUrl;
    });

    if (this.apiUrl) {
      this.client
        .get(`${this.configurationService.serviceApiBaseUrl}/v2/Files?file=PrivacyPolicy.pdf`, {
          responseType: 'arraybuffer',
        })
        .pipe(
          map((blob) => {
            if (!blob) throw new Error('No blob file found with this file name');
            return blob;
          }),
          tap((blob) => this._privacyPolicyURI.next(blob as Uint8Array))
        )
        .subscribe();
    } else {
      throw new Error('Invalid API url');
    }
  }

  get pdf(): Uint8Array {
    return this._privacyPolicyURI.value!;
  }
}
