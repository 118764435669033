<ng-container [ngSwitch]="unsubscribeStatus">
  <ng-container *ngSwitchCase="'unchanged'">
    <div style="padding: 15px 35px" id="cancel-enrollment">
      <!--<div>
        To manage which messages you receive but still earn great rewards,
        <a [routerLink]="['/unsubscribe']">click here</a>
      </div>-->
      <div>
        To cancel your enrollment and stop receiving your rewards, click the button below to finalize your cancellation
      </div>

      <div>
        <button pButton pRipple label="Cancel Enrollment" type="button" (click)="cancelEnrollment()"></button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'cancelled'">
    <div class="status-padding">Your enrollment has been cancelled</div>
  </ng-container>
  <ng-container *ngSwitchCase="'error'">
    <div class="status-padding">There was an error with cancelling your enrollment</div>
  </ng-container>
</ng-container>
