import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouteReusableStrategy, ErrorHandlerInterceptor, SharedModule, ApiPrefixInterceptor } from '@shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReceiptModule } from '@app/receipt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigurationService } from '@ep/shared';
import { DemoModule } from './demo/demo.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { getEnvironment } from '@shared/utils/environment';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: false }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    NgbModule,
    SharedModule,
    ShellModule,
    ReceiptModule,
    PdfViewerModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (configurationService: ConfigurationService) => () => configurationService.load(getEnvironment()),
      deps: [ConfigurationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
