import { Directive, inject, OnDestroy, OnInit } from '@angular/core';
import { ReceiptType } from './services';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger } from '@app/@shared';
import { ReceiptAPIService } from '@app/receipt/services';
import { SubSink } from 'subsink';
import { MessageService } from 'primeng/api';
import { I18nService } from '@app/i18n';

@Directive({
  selector: 'app-receipt',
  standalone: true,
  providers: [MessageService],
})
export abstract class ReceiptComponent implements OnInit, OnDestroy {
  protected messageService = inject(MessageService);
  protected receiptApiService = inject(ReceiptAPIService);
  protected i18nService = inject(I18nService);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);

  protected logger = new Logger('ReceiptComponent');
  protected receipt: ReceiptType | null = null;
  protected isLoading: boolean = false;
  protected subs = new SubSink();

  abstract ngOnInit(): void;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  protected showToastError() {
    this.messageService.add({ key: 'tc', severity: 'error', summary: 'Error', detail: 'Error retrieving e-receipt' });
  }

  protected getReceiptFromValue(value: any) {
    const transaction = Object.fromEntries(
      Object.entries(value.transaction).filter(([key, value]) => !key.includes('$id') && !!value)
    );
    this.receipt = {
      OrderPaymentGuid:
        value.orderPaymentGuid.length > 12
          ? value.orderPaymentGuid.substr(value.orderPaymentGuid.length - 12)
          : value.orderPaymentGuid,
      TransactionDate: value.orderDatetime,
      Merchant: {
        name: value.merchant.name,
        address: value.merchant.address,
        locale: value.merchant.locale,
        phoneNumber: value.merchant.phoneNumber,
      },
      Products: value.products.$values.map((v: any) => ({
        name: v.name,
        quantity: v.quantity,
        price: v.price,
      })),
      Price: {
        subtotal: value.price.subtotal,
        tax: value.price.tax,
        tip: value.price.tip,
        rewards: value.price.rewards,
        total: value.price.total,
      },
      Reward: this.getReceiptRewardInfo(value.reward),
      Transaction: transaction,
    };
  }

  private getReceiptRewardInfo(rewardString: string) {
    if (rewardString === '') return rewardString;
    let reward = '';
    const obj = JSON.parse(rewardString);
    // Check against OrderPayment AdditionalAttributes
    if ('RecapLanguages' in obj) {
      reward = JSON.parse(obj['RecapLanguages'])[this.i18nService.language];
    } // Check against OrderPayment commands
    else {
      for (const idx in obj) {
        if (!obj[idx]['Recipients']) continue;
        reward = obj[idx]['Recipients'][0]['RecapLanguages'][this.i18nService.language];
        break;
      }
    }
    return reward ?? '';
  }
}
