<div style="padding: 20px">
  <h2>Member Terms and Conditions</h2>
  <p>
    This Member Rewards Agreement ("Agreement") governs the terms and conditions between Payment Integrations, Inc.,
    doing business as Enroll &amp; Pay, and its affiliates ("we", "us" or "our") and an individual ("you" or "your"). By
    completing enrollment into Enroll &amp; Pay, you hereby accept the terms and conditions in this agreement
    ("Agreement") and may participate in the participating merchant’s Rewards Program ("Program") offered by Enroll
    &amp; Pay.
  </p>
  <ol>
    <li>
      <p>
        To become a member of and participate in our Program you must be an United States resident and of legal age and
        otherwise be able to enter into a legally binding contract, complete our enrollment process, and agree to the
        terms of this Agreement.
      </p>
    </li>
    <li>
      <p>
        To participate in the Program, you must have a valid Visa®, MasterCard®, American Express® or Discover® payment
        card that is registered with us ("Registered Card"). To enroll in the Program, you must agree to register your
        payment card during time of purchase via mobile device or terminal presented to you.
      </p>
    </li>
    <li>
      <p>
        You are responsible for registering a new payment card with us if your Registered Card is no longer valid. You
        must notify us if your name or e-mail address changes.
      </p>
    </li>
    <li>
      <p>
        We provide you information about the Program, and you agree to receive, by posting such information on our
        website at www.enrollandpay.com (“Website”). We may provide information or notices to you by other means,
        including e-mail, but we are not required to do so. You agree to visit our Website regularly to view any changes
        to this Agreement or other important information about your participation in the Program.
      </p>
    </li>
    <li>
      <p>
        You are eligible to earn cash and/or points credits ("Rewards") on your Registered Card as provided in this
        Agreement when you use a Registered Card to make a purchase at a Participating Merchant. Participating Merchants
        will have designated minimum reward thresholds that must be met by member, in order to receive redeemable
        reward. Reward Thresholds are outlined in Reward Program emails to member.
      </p>
    </li>
    <li>
      <p>
        You will receive Rewards for a purchase at participating merchants only if the business is a participating on
        the date of the purchase. You will not receive Rewards for a purchase if a participating merchant indicates that
        no Rewards are being offered or given for the type of purchase you made.
      </p>
    </li>
    <li>
      <p>
        The Rewards that you earn may vary depending on a variety of factors, such as, but not limited to, holidays or
        special events, and your level of activity within the Program. Information on the type, amount and limits on
        Rewards is posted on participating merchant’s website and/or storefront. You are responsible for reviewing this
        information before you make a purchase at a Merchant, as these details may change from time to time and may
        affect the Rewards you receive.
      </p>
    </li>
    <li>
      <p>
        We reserve the right to require that you provide the receipt from the Participating Merchant for a purchase in
        order to receive your Reward for the purchase that displays the last four (4) digits of your Registered Card.
      </p>
    </li>
    <li>
      <p>
        You are responsible for any tax consequences resulting from your receipt or use of Rewards under the Program. We
        may report Rewards provided to you to relevant tax authorities if requested by such authorities.
      </p>
    </li>
    <li>
      <p>
        You agree that, (a) Any claim, dispute, or controversy between you and us, our subsidiaries, our affiliates,
        and/or any holder of this Agreement, or the employees, agents or assigns of any of them (collectively, "Payment
        Integrations, Inc."), arising from or relating to the Program, this Agreement (or any prior agreement between
        you and us), or the relationships that result from this Agreement ("Claim"), including but not limited to any
        tort or statutory Claim, and any Claim seeking monetary, equitable, or other relief will be, upon the election
        of any party to the dispute, resolved by neutral binding arbitration administered by the National Arbitration
        Forum ("NAF"), under the Code of Procedure ("Code") of the NAF in effect at the time the Claim is filed. Claims
        include past, present, and future Claims. Any arbitration hearing at which the parties appear personally will
        take place in San Diego, California within the United States federal judicial district. Rules and forms of the
        NAF may be obtained and all claims will be filed at any NAF office, www.adrforum.com or by calling
        1-800-474-2371. (b) You and Payment Integrations, Inc. will each be responsible for paying its own fees, costs
        and expenses and the arbitration fees as designated by the Code. The arbitrator’s power to conduct any
        arbitration proceeding under this arbitration agreement will be limited as follows: any arbitration proceeding
        under this Agreement will not be consolidated or joined with any arbitration proceeding under any other
        agreement, or involving any other property or premises, and will not proceed as a class action or any other
        proceeding involving multiple claimants. Only a court, not an arbitrator, may determine the validity of the
        preceding sentence. Payment Integrations, Inc. will not elect to arbitrate an individual Claim brought in a
        small claims (or equivalent) court, unless that Claim is transferred, removed, or appealed to a different court.
        The arbitrator will apply all applicable substantive law. (c) The decision of the arbitrator will be a final and
        binding resolution of the Claim. This arbitration agreement is made pursuant to a transaction involving
        interstate commerce and will be governed by the Federal Arbitration Act, 9 U.S.C. Sections 1-16. Judgment upon
        the award may be entered in any court having jurisdiction. The parties understand that they would have had a
        right or opportunity to litigate disputes through a court, to have a judge or jury decide their case, and to
        participate in a class action or other proceeding involving multiple claimants, but they choose to have any
        disputes decided through individual arbitration. (d) You can reject the arbitration provisions in Section 12(a),
        (b), and (c) ("Arbitration Provisions") by providing us written notice, signed by you, within thirty (30) days
        of enrolling in the Program for the first time. Please send the written notice to Payment Integrations, Inc.,
        426 N. Cedros Ave Solana Beach, CA 92075; ATTN: Chief Compliance Officer.
      </p>
    </li>
    <li>
      <p>
        You may cancel this Agreement at any time by logging into your Member Account and following the Cancel
        Membership instructions. We may cancel this Agreement at any time, with or without cause, by providing you
        notice. We also may cancel this Agreement, or suspend your participation in the Program, immediately and without
        notice if (i) You do not perform your obligations under this Agreement, (ii) You provide us any false or
        misleading information, (iii) You engage in any illegal or improper transaction, (iv) You engage in behavior we
        deem to be abusive to us, our employees, or to the Program, whether directly or indirectly.
      </p>
    </li>
    <li>
      <p>
        All comments, feedback, reviews, and other information disclosed, submitted, or conveyed by you to us
        (collectively, the "Comments") shall be and remain our property and may be reprinted by us or others without
        your permission. Your provision of Comments to us constitutes an assignment to us of all worldwide rights,
        titles, and interests in all intellectual properties of the Comments. You warrant that: (i) Any use of Comments
        by us will not violate any right of any third party; and (ii) Any Comments are not libelous, unlawful, or
        obscene.
      </p>
    </li>
    <li>
      <p>
        You agree that, (a) The Program is provided "as is" and, to the maximum extent permitted by law, we disclaim any
        express or implied warranties with respect to the Program, including any implied warranties of merchantability
        or fitness for a particular purpose. (b) Enroll &amp; Pay will not be liable for any damage that you may suffer
        in connection with your participation in the program, and unless the damage results directly from our failure to
        perform our express obligations under this Agreement. In no event will Enroll &amp; Pay be liable for any damage
        caused by any act or omission of a Participating Merchant, Third Party Provider or an Other Program Operator.
        (c) Enroll &amp; Pay will not be liable to you or any other person for any indirect, special, incidental,
        contingent, consequential, reliance or special damages (including lost profits) in connection with this
        Agreement or the Program, whether or not Enroll &amp; Pay has been advised of the possibility of such damages.
      </p>
    </li>
    <li>
      <p>
        You agree to defend, indemnify and hold us and our subsidiaries, affiliates, successors and assigns, and our and
        their respective shareholders, directors, officers, employees and agents, harmless from and against any and all
        claims, damages, costs and expenses, including attorneys' fees, arising from or relating to your alleged
        violation of this Agreement, use of the Website, or your improper participation in our Program.
      </p>
    </li>
    <li>
      <p>
        You agree that we may provide to (and receive from) Participating Merchants, Third Party Providers, and the
        issuer of your Registered Card, information about you, your Registered Card and your participation in the
        Program, in connection with our operation of the Program, including in connection with determining your
        eligibility for Rewards and compliance with this Agreement, and requesting that you receive Rewards.
      </p>
    </li>
    <li>
      <p>
        Participating Merchants, Third Party Providers and Other Program Operators are not our agents, and have no
        authority to take any action or make any commitments on our behalf. You and we are the only parties that may
        enforce this Agreement; there are no third party beneficiaries of this Agreement (except persons expressly
        included in some sections, who shall be included to enforce the provisions of those sections). The Website may
        contain links to third party services and resources. We do not control the availability and content of these
        third party Websites. Any concerns regarding any such service or resource, or any link thereto, should be
        directed to the particular third party service or resource that provides the content. The existence of a link to
        a third party Website does not constitute our endorsement or recommendation of the third party or the third
        party's Website. We disclaim all liability for any loss or damages arising from the content or provision of
        services of any third party service or resource. You must receive our prior written permission before creating a
        link to the Website. Any unauthorized links or false or misleading uses of the Website or our trademarks or
        service marks is prohibited. You may not use any robot, spider or other device to monitor Content on the
        Website.
      </p>
    </li>
    <li>
      <p>
        We may establish an account on a participating merchant’s website for you ("Member Account") in which you can
        obtain information, from time to time, about your participation in our Program. Access to your Member Account at
        the Website requires you to use a login identification ("Login ID") and password ("Password") that you select.
        You agree to keep your Login ID and Password confidential and not share them with anyone else. We may treat any
        person using your Login ID and Password to have authority to access your Member Account and act on your behalf
        with respect to the Program.
      </p>
    </li>
    <li>
      <p>
        We grant you a non-exclusive, non-transferable, limited right to access, display and use the Website, and all
        images, illustrations, designs, icons, photographs, software, video clips, and written and other materials
        ("Contents") on a participating merchant’s website, for your personal, non-commercial purposes. We also
        authorize you to download Content on the Website, subject to your agreement that: (1) you will not obtain any
        ownership or title to the Content; (2) you may use the Content solely for your personal use in learning about,
        evaluating, or participating in programs offered by us, and not for any commercial purposes; (3) you may not
        modify the Content, or sell, assign or transfer the Content to any person; (4) you may not reproduce, disclose,
        display or distribute the Content in any public or commercial manner; and (5) you will not alter or remove any
        copyright notice, proprietary legend or other notice contained in any Content. The Website as a whole, and all
        Content, is protected by copyright, trademarks, trade dress and/or other intellectual properties owned by or
        licensed to us, unless otherwise noted. The Website contains our service marks, including, but not limited to
        Enroll &amp; Pay; Community Cash Back®; Enroll &amp; Pay Rewards. All rights in the intellectual property
        relating to the Website and any Content are reserved, and you agree not to take any action to register or
        otherwise interfere with or challenge any intellectual property rights relating to the Website or the Content.
      </p>
    </li>
    <li>
      <p>
        You agree not to, (1) disrupt or interfere with the security of, or otherwise abuse, the Website, or any
        services, system resources, accounts, servers or networks connected to or accessible through the Website or
        affiliated or linked web Websites; (2) disrupt or interfere with any other persons access, use or enjoyment of
        the Website or affiliated or linked web Websites; (3) upload, post or otherwise transmit through or on the
        Website any viruses or other harmful, disruptive or destructive files; (4) use or attempt to use or access
        another person's account or personal information, or create or use a false identity on the Website; (5) attempt
        to obtain unauthorized access to the Website or portions of the Website which are restricted from general
        access; and (6) violate any local, state or federal laws or regulations that apply to your access to or use of
        the Website.
      </p>
    </li>
    <li>
      <p>
        This Agreement is binding upon you at the time you enroll, or are enrolled by a Third Party Provider, in a
        Program. We may, from time to time, change this Agreement (including any Rewards available under a Program) and
        information provided on the Website (the "Information"), by adding, modifying or deleting any term or condition
        ("Changed Term"). A Changed Term will be effective upon posting to participating merchant’s website, although we
        may provide you notice by other means. We reserve the right, at our sole discretion, to modify, add or remove
        ("Change") any portion of this Agreement in whole or in part, at any time without notice. Changes in this
        Agreement will be effective when posted on the Website. Your continued use of the Website after any Changes to
        this Agreement are posted will be considered acceptance of those Changes. We may terminate, change, suspend or
        discontinue any aspect of the Website, including the availability of any features of the Website, at any time.
        We also may impose limits on certain features and services or restrict your access to part or all of the Website
        without notice or liability. While efforts have been made to make the Website helpful and accurate, we do not
        warrant the accuracy of the Information. We do not provide a warranty regarding virus-free use of the Website or
        use of any Content downloaded from the Website. The services provided by us with respect to the Website and all
        content on the Website are provided "as is" and, to the maximum extent permitted by law, we disclaim all
        warranties express or implied, including the implied warranty of merchantability and fitness for a particular
        purpose and any warranty that the services supplied under this agreement are of a reasonably acceptable quality.
        We do not warrant that functions contained on the Website will be uninterrupted or error-free, that defects will
        be corrected, or that the Website, content or the server are free of viruses or other harmful components.
      </p>
    </li>
    <li>
      <p>
        You acknowledge, by your use of the Website, that such use is at your sole risk and that we will not be liable
        for any damages, including without limitation direct, indirect, special, incidental, contingent, consequential,
        reliance or special damages, losses or expenses arising in connection with your use of the Website, whether or
        not we have been advised of the possibility of such damages. We will not be liable for unauthorized access by
        third parties to your transmission facilities or premise equipment or for unauthorized access to or alteration,
        theft, loss or destruction of your network, systems, applications, data files, programs, procedures, or
        information through accident, fraudulent means or devices, or any other method. These limitations of liability
        shall apply, to the fullest extent permitted by law, regardless of the form of action, whether in contract, or
        tort, and shall survive failure of an exclusive remedy.
      </p>
    </li>
    <li>
      <p>
        You agree to the terms of our Privacy Policy by using the Website, as posted on our Website from time to time
        and understand the terms of the Privacy Policy are hereby incorporated into this Agreement. Our Program Privacy
        Policy describes the types of information that we may collect when you visit the Website and how we may disclose
        that information.
      </p>
    </li>
    <li>
      <p>
        If any part of this Agreement is unenforceable, such part will not make any other part of this Agreement
        unenforceable, except that if the part of the Arbitration Provisions prohibiting arbitration involving a class
        action or multiple claimants is unenforceable, the entire Section 12 shall be unenforceable.
      </p>
    </li>
    <li>
      <p>
        You may not assign your rights under the Agreement or in the Program without our prior written consent. We may
        assign this Agreement, or any or all of our rights in the Program, without notice to you.
      </p>
    </li>
    <li>
      <p>
        Any waiver by us of our rights under this Agreement must be in writing and signed by us. However, a waiver of a
        right by us in one instance will not limit our ability to enforce the right in another instance.
      </p>
    </li>
    <li>
      <p>
        This Agreement, and the relationship between you and us, shall be governed by the laws of the State of
        California, without regard to choice of law principles, except that Section 12 shall be governed by the Federal
        Arbitration Act and not California law.
      </p>
    </li>
  </ol>
</div>
