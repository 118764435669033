import { Component } from '@angular/core';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { NgSwitch, NgSwitchCase } from '@angular/common';

type UnsubscribeStatus = 'unchanged' | 'complete' | 'error';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, ButtonModule, CheckboxModule, FormsModule, ReactiveFormsModule],
})
export class UnsubscribeComponent {
  unsubscribeForm = this.fb.group({
    Offers: this.fb.control<boolean>(true),
    Receipts: this.fb.control<boolean>(true),
    Updates: this.fb.control<boolean>(true),
  });

  protected unsubscribeStatus: UnsubscribeStatus = 'unchanged';

  constructor(private fb: NonNullableFormBuilder) {}

  updateSubscriptions() {
    this.unsubscribeStatus = 'complete';
  }
}
