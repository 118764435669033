import { inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { LoginRequest } from '@app/@shared/models/login-request.model';
import { Credentials, LoginResponse, Resources } from '@shared/models';
import { ConfigurationService } from '@ep/shared';
import { getEnvironment } from '@shared/utils/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private client = inject(HttpClient);
  public redirectUrl: string | null = null;
  constructor(private configurationService: ConfigurationService) {
    this.configurationService.load(getEnvironment());
  }
  login(loginRequest: LoginRequest): Observable<LoginResponse> {
    const json = JSON.stringify(loginRequest);
    return this.client.post<LoginResponse>(
      `${this.configurationService.getConfigValue('legacyServiceApiBaseUrl')}/Login`,
      json,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  }

  logout(): Observable<void> {
    return this.client.get<void>(`${this.configurationService.getConfigValue('legacyServiceApiBaseUrl')}/Logout`).pipe(
      tap(() => {
        const savedCredentials = localStorage.getItem(Resources.CredentialsKey);
        if (savedCredentials) {
          const credentials: Credentials = JSON.parse(savedCredentials);

          credentials.jwt = null;
          const updatedCredentialsJSON = JSON.stringify(credentials);

          localStorage.setItem(Resources.CredentialsKey, updatedCredentialsJSON);
        }
      })
    );
  }
}
