import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReceiptMerchantType } from '../../services';

@Component({
  selector: 'app-receipt-merchant',
  templateUrl: './merchant-information.component.html',
  styleUrls: ['./merchant-information.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class ReceiptMerchantComponent {
  @Input() merchant: ReceiptMerchantType | null = null;
}
