import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { NgSwitch, NgSwitchCase } from '@angular/common';
import { RouterModule } from '@angular/router';

type EnrollmentStatus = 'unchanged' | 'cancelled' | 'error';

@Component({
  selector: 'app-enrollment-cancellation',
  templateUrl: './enrollment-cancellation.component.html',
  styleUrls: ['./enrollment-cancellation.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, ButtonModule, RouterModule],
})
export class EnrollmentCancellationComponent {
  protected unsubscribeStatus: EnrollmentStatus = 'unchanged';

  cancelEnrollment() {
    this.unsubscribeStatus = 'cancelled';
  }
}
