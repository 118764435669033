import { CurrencyPipe, KeyValuePipe, NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReceiptTransactionType } from '../../services';
import { TransactionDataKeyMap } from './transaction-data.map';

@Component({
  selector: 'app-receipt-transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, CurrencyPipe, KeyValuePipe],
})
export class ReceiptTransactionSummaryComponent implements OnInit {
  @Input() transaction: ReceiptTransactionType | null = null;
  filteredTransactionData: ReceiptTransactionType | null = null;

  ngOnInit(): void {
    if (this.transaction) this.filteredTransactionData = this.getFilteredTransactionData(this.transaction);
  }

  protected getFilteredTransactionData(transaction: ReceiptTransactionType): ReceiptTransactionType {
    const filteredEmptyValues = Object.entries(transaction).filter(([_, value]) => !!value);
    return Object.fromEntries(
      filteredEmptyValues.reduce((result, [key, value]) => {
        if (!!TransactionDataKeyMap[key]) result.push([TransactionDataKeyMap[key], value]);
        return result;
      }, [] as [string, any][])
    );
  }

  // Needed in order for the keyvalue pipe to return the data unsorted
  protected unsortComparator = () => {
    return 0;
  };
}
