import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  PageNotFoundComponent,
  PrivacyStatementComponent,
  TermsOfServiceComponent,
  UnsubscribeComponent,
} from '@shared';
import { EnrollmentCancellationComponent } from '@shared/enrollment-cancellation';
import { OrderPaymentReceiptComponent, OrderReviewReceiptComponent } from './receipt';
import { AuthenticationGuard } from '@shared/guards/authentication.guard';
import { LoginComponent } from '@app/auth/login/login.component';

const routes: Routes = [
  /**
   * While receipt is now a sub-module of merchant, we need to support legacy links for e-receipts
   */
  {
    path: 'en-us/r/:orderReviewId',
    component: OrderReviewReceiptComponent,
    data: { title: marker('E-Receipt') },
  },
  {
    path: 'en-us/p/:orderPaymentGuid',
    component: OrderPaymentReceiptComponent,
    data: { title: marker('E-Receipt') },
  },
  {
    path: 'en-us/privacy-statement',
    component: PrivacyStatementComponent,
    data: { title: marker('Privacy Statement') },
  },
  {
    path: 'en-us/manage-preferences',
    component: UnsubscribeComponent,
    data: { title: marker('Manage Preferences') },
  },
  {
    path: 'en-us/enrollment-cancellation',
    component: EnrollmentCancellationComponent,
    data: { title: marker('Cancel Enrollment') },
  },
  {
    path: 'en-us/terms-of-service',
    component: TermsOfServiceComponent,
    data: { title: marker('Terms of Service') },
  },
  {
    path: 'TermsAndConditions',
    component: TermsOfServiceComponent,
    data: { title: marker('Terms and Conditions') },
  },
  {
    path: 'show',
    loadChildren: () => import('./demo/demo.module').then((m) => m.DemoModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: PageNotFoundComponent,
    data: { title: marker('Page Not Found') },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: marker('Page Not Found') },
  }, // This line must always be last in the routes array
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
