import { Injectable } from '@angular/core';
import { ReceiptType } from '@app/receipt/services';
import { BaseApiService } from '@ep/shared';

@Injectable({
  providedIn: 'root',
})
export class OrderPaymentService extends BaseApiService {
  private readonly baseUrl = `/v2/OrderPayments`;

  public getOrderPaymentByGuid(orderPaymentGuid: string) {
    return this.get(`${this.baseUrl}/${orderPaymentGuid}`);
  }

  public getReceiptByOrderPaymentGuid(orderPaymentGuid: string) {
    return this.get<ReceiptType>(`${this.baseUrl}/${orderPaymentGuid}/receipt`);
  }
}
