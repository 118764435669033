import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ENPCurrencyPipe } from '@app/@shared/pipes';
import { ReceiptPriceReviewType } from '../../services';

@Component({
  selector: 'app-receipt-price-review',
  templateUrl: './price-review.component.html',
  styleUrls: ['./price-review.component.scss'],
  standalone: true,
  imports: [NgIf, ENPCurrencyPipe],
})
export class ReceiptPriceReviewComponent {
  @Input() priceReview: ReceiptPriceReviewType | null = null;
}
