import { Injectable } from '@angular/core';
import { ReceiptType } from '@app/receipt/services';
import { BaseApiService } from '@ep/shared';

@Injectable({
  providedIn: 'root',
})
export class OrderReviewService extends BaseApiService {
  private readonly baseUrl = `/v2/OrderReviews`;

  public getOrderReviewById(orderReviewId: string) {
    return this.get(`${this.baseUrl}/${orderReviewId}`);
  }

  public getReceiptByOrderReviewId(orderReviewId: string) {
    return this.get<ReceiptType>(`${this.baseUrl}/${orderReviewId}/receipt`);
  }
}
