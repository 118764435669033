<div class="receipt-price-review-wrapper" *ngIf="priceReview">
  <div class="receipt-price-review">
    <span>Subtotal</span>
    <span>{{ priceReview.subtotal | currency }}</span>
  </div>
  <div *ngIf="priceReview.tax" class="receipt-price-review">
    <span>Tax</span>
    <span>{{ priceReview.tax | currency }}</span>
  </div>
  <div *ngIf="priceReview.tip" class="receipt-price-review">
    <span>Tip</span>
    <span>{{ priceReview.tip | currency }}</span>
  </div>
  <div *ngIf="priceReview.rewards" class="receipt-price-review" id="receipt-price-discount">
    <span>Rewards</span>
    <span>-{{ priceReview.rewards | currency }}</span>
  </div>
  <div class="receipt-price-review" id="receipt-price-total">
    <span>Total</span>
    <span>{{ priceReview.total | currency }}</span>
  </div>
  <div *ngIf="priceReview.otherPayments" class="receipt-price-review" id="receipt-price-total">
    <span>Other Payments</span>
    <span>{{ priceReview.otherPayments | currency }}</span>
  </div>
  <div *ngIf="priceReview.approved" class="receipt-price-review" id="receipt-price-total">
    <span>Approved</span>
    <span>{{ priceReview.approved | currency }}</span>
  </div>
  <div *ngIf="priceReview.balanceRemaining" class="receipt-price-review" id="receipt-price-total">
    <span>Remaining balance</span>
    <span>{{ priceReview.balanceRemaining | currency }}</span>
  </div>
</div>
