import { Injectable } from '@angular/core';
import { Resources } from '@shared/models/constants';
import { Credentials } from '@shared/models/credentials.model';
import { jwtDecode } from 'jwt-decode';
import { AccessType } from '@shared/enums/access-types.enum';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials | null = null;

  constructor() {
    const savedCredentials =
      sessionStorage.getItem(Resources.CredentialsKey) || localStorage.getItem(Resources.CredentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  get credentials(): Credentials | null {
    return this._credentials;
  }

  isAuthenticated(): boolean {
    return !!this.credentials?.jwt && !this.isTokenExpired(this.credentials.jwt);
  }

  setCredentials(credentials?: Credentials): void {
    this._credentials = credentials || null;

    if (credentials && credentials.jwt) {
      localStorage.setItem(Resources.CredentialsKey, JSON.stringify(credentials));
    }
  }

  private isTokenExpired(token: string): boolean {
    const decoded: any = this.getDecodedAccessToken(token);
    if (!decoded.exp) {
      return true; // If there's no expiry information, assume it's expired.
    }

    const expiryDateInMs = decoded.exp * 1000;
    const currentDateInMs = Date.now();

    return currentDateInMs >= expiryDateInMs;
  }

  private getDecodedAccessToken(token: string | null): any {
    try {
      if (!token) {
        return null;
      }
      return jwtDecode(token);
    } catch (Error) {
      return null;
    }
  }
}
