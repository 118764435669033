import { inject, Injectable } from '@angular/core';
import { OrderPaymentService, OrderReviewService } from '@app/resources/services';
import { catchError } from 'rxjs';
import { ReceiptServiceInterface } from './receipt.service.interface';

@Injectable()
export class ReceiptAPIService implements ReceiptServiceInterface {
  private orderPaymentService = inject(OrderPaymentService);
  private orderReviewService = inject(OrderReviewService);

  getReceiptByOrderReviewId(orderReviewId: string) {
    return this.orderReviewService.getReceiptByOrderReviewId(orderReviewId).pipe(
      catchError((e) => {
        console.error(e);
        throw new Error('Issue with getting e-receipt from order review');
      })
    );
  }

  getReceiptByOrderPaymentGuid(orderPaymentGuid: string) {
    return this.orderPaymentService.getReceiptByOrderPaymentGuid(orderPaymentGuid).pipe(
      catchError((e) => {
        console.error(e);
        throw new Error('Issue with getting e-receipt from order payment');
      })
    );
  }
}
