<p-toast position="top-center" key="tc"></p-toast>
<div *appLoading="isLoading || !!!receipt" id="receipt-wrapper">
  <div *ngIf="receipt" class="receipt">
    <div id="receipt-form">
      <p-card>
        <div id="receipt-form-card-wrapper">
          <app-receipt-merchant [merchant]="receipt.Merchant"></app-receipt-merchant>
          <app-receipt-order-review
            [orderPaymentGuid]="receipt.OrderPaymentGuid"
            [orderDate]="receipt.TransactionDate"
            class="receipt-item"
          >
          </app-receipt-order-review>
          <app-receipt-product *ngIf="receipt.Products.length" [products]="receipt.Products"></app-receipt-product>
          <app-receipt-price-review [priceReview]="receipt.Price" class="receipt-item"></app-receipt-price-review>
          <app-receipt-rewards-review *ngIf="!!receipt.Reward" [reward]="receipt.Reward"></app-receipt-rewards-review>
          <app-receipt-transaction-summary
            class="receipt-item"
            [transaction]="receipt.Transaction"
          ></app-receipt-transaction-summary>
        </div>
      </p-card>
    </div>
    <app-links-footer style="margin-top: 30px"></app-links-footer>
  </div>
</div>
