<p-card id="order-review-card">
  <div id="order-review-wrapper">
    <div class="order-review-details">
      <span>Order #: </span>
      <span>{{ orderPaymentGuid }}</span>
    </div>
    <div class="order-review-details">
      <span>Date: </span>
      <span>{{ orderDate | date : 'short' }}</span>
    </div>
  </div>
</p-card>
