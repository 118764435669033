import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CardModule } from 'primeng/card';

@Component({
  selector: 'app-receipt-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss'],
  standalone: true,
  imports: [DatePipe, CardModule],
})
export class ReceiptOrderReviewComponent {
  @Input() orderPaymentGuid: string = '';
  @Input() orderDate: Date = new Date();
}
