export function getEnvironment(): string {
  switch (location.origin) {
    case 'http://localhost:4200':
      return 'staging';
    case 'https://staging-consumer.enrollandpay.com':
      return 'staging';
    case 'https://preproduction-consumer.enrollandpay.com':
      return 'preprod';
    case 'https://consumer.enrollandpay.com':
      return 'prod';
    default:
      throw Error('Unexpected base URL');
  }
}
