import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Resources } from '@app/@shared';
import { Credentials } from '@app/@shared';

@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt: string = this.getJwt();

    // Create headers with Authorization Bearer token.
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    // Check if jwt has a value, and then add the Authorization header.
    if (jwt) {
      headers = headers.append('Authorization', `Bearer ${jwt}`);
    }

    // Clone the request and include the headers.
    let modifiedRequest = request.clone({ headers });

    // Prepend environment.serverUrl to requests without http/https.
    if (!/^(http|https):/i.test(modifiedRequest.url)) {
      modifiedRequest = modifiedRequest.clone({ url: modifiedRequest.url });
    }

    return next.handle(modifiedRequest);
  }

  private getJwt(): string {
    const cachedItem = localStorage.getItem(Resources.CredentialsKey);

    if (cachedItem) {
      const cachedData: Credentials = JSON.parse(cachedItem);
      return cachedData.jwt || '';
    }

    return '';
  }
}
