import { CurrencyPipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { I18nService } from '@app/i18n';

@Pipe({
  name: 'currency',
  pure: true,
  standalone: true,
})
export class ENPCurrencyPipe extends CurrencyPipe {
  constructor(private i18nService: I18nService) {
    super(i18nService.language);
  }
}
