<footer style="margin: 10px">
  <div id="links">
    <div class="divided-links">
      <button (click)="navigateTo('cancel')">{{ 'Cancel Enrollment' | translate }}</button>
    </div>
    <a href="https://www.enrollandpay.com/contact/"> {{ 'Refer a Business' | translate }} </a>
    <div class="divided-links">
      <button (click)="navigateTo('privacy')">{{ 'Privacy Statement' | translate }}</button>
      <div class="link-divider">|</div>
      <button (click)="navigateTo('terms of service')">{{ 'Terms of Service' | translate }}</button>
    </div>
    <a href="https://www.enrollandpay.com" id="main-site-link"> {{ 'Enroll & Pay' | translate }}</a>
  </div>
</footer>
