import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptAPIService } from './services';
import { CardModule } from 'primeng/card';
import { ENPCurrencyPipe } from '@app/@shared/pipes';
import {
  ReceiptMerchantComponent,
  ReceiptOrderReviewComponent,
  ReceiptPriceReviewComponent,
  ReceiptProductComponent,
  ReceiptRewardsReviewComponent,
  ReceiptTransactionSummaryComponent,
} from './components';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

const IMPORTS_AND_EXPORTS: (any[] | Type<any>)[] = [
  ReceiptOrderReviewComponent,
  ReceiptMerchantComponent,
  ReceiptPriceReviewComponent,
  ReceiptProductComponent,
  ReceiptRewardsReviewComponent,
  ReceiptTransactionSummaryComponent,
];

@NgModule({
  imports: [...IMPORTS_AND_EXPORTS, CommonModule, CardModule, ENPCurrencyPipe, ToastModule],
  exports: [...IMPORTS_AND_EXPORTS],
  providers: [ReceiptAPIService, MessageService],
})
export class ReceiptModule {}
