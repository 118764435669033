import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { I18nService } from '@app/i18n';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-links-footer',
  templateUrl: './links-footer.component.html',
  styleUrls: ['./links-footer.component.scss'],
  standalone: true,
  imports: [RouterModule, TranslateModule],
})
export class LinksFooterComponent {
  constructor(private i18nService: I18nService, private router: Router) {}

  protected navigateTo(route: string) {
    const culture = this.i18nService.language.toLocaleLowerCase();
    switch (route) {
      case 'cancel':
        this.router.navigateByUrl(`${culture}/enrollment-cancellation`);
        return;
      case 'preferences':
        this.router.navigateByUrl(`${culture}/manage-preferences`);
        return;
      case 'privacy':
        this.router.navigateByUrl(`${culture}/privacy-statement`);
        return;
      case 'terms of service':
        this.router.navigateByUrl(`${culture}/terms-of-service`);
        return;
      default:
        return '';
    }
  }
}
