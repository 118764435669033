import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ENPCurrencyPipe } from '@app/@shared/pipes';
import { ReceiptProductType } from '../../services';

@Component({
  selector: 'app-receipt-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  standalone: true,
  imports: [NgForOf, ENPCurrencyPipe],
})
export class ReceiptProductComponent {
  @Input() products: ReceiptProductType[] = [];
}
