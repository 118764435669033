import { NgForOf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-receipt-rewards-review',
  templateUrl: './rewards-review.component.html',
  styleUrls: ['./rewards-review.component.scss'],
  standalone: true,
  imports: [NgForOf],
})
export class ReceiptRewardsReviewComponent implements OnInit {
  @Input() reward: string = '';
  displayReward: string[] = [];

  ngOnInit(): void {
    this.displayReward = this.reward.split('\n');
  }
}
