import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { NgForOf, NgIf } from '@angular/common';
import { LinksFooterComponent } from '@app/@shared/links-footer/links-footer.component';
import { LoadingSpinnerDirective } from '@ep/shared';
import { ReceiptComponent } from '../receipt.component';
import { ReceiptModule } from '../receipt.module';
import { ToastModule } from 'primeng/toast';
import { catchError } from 'rxjs';

@Component({
  selector: 'app-order-payment-receipt',
  templateUrl: '../receipt.component.html',
  styleUrls: ['../receipt.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, LinksFooterComponent, ReceiptModule, CardModule, LoadingSpinnerDirective, ToastModule],
})
export class OrderPaymentReceiptComponent extends ReceiptComponent {
  ngOnInit(): void {
    try {
      this.isLoading = true;
      // Throw error if the path parameter for the order id is not a number
      const orderPaymentGuid = this.route.snapshot.paramMap.get('orderPaymentGuid');
      if (!!!orderPaymentGuid) throw new Error('Cannot parse path parameter for receipt');

      this.subs.sink = this.receiptApiService
        .getReceiptByOrderPaymentGuid(orderPaymentGuid)
        .pipe(
          catchError((e) => {
            this.showToastError();
            this.logger.error('Cannot load receipt');
            throw e;
          })
        )
        .subscribe((value: any) => {
          if (value) {
            this.getReceiptFromValue(value);
            this.isLoading = false;
          }
        });
    } catch (e) {
      this.logger.error('Invalid path parameter value');
    }
  }
}
