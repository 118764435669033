<ng-container [ngSwitch]="unsubscribeStatus">
  <ng-container *ngSwitchCase="'unchanged'">
    <div id="status-unchanged" class="status-padding">
      <p>
        You are currently subscribed to the following emails. To stop receiving all emails, uncheck all boxes and click
        "Update".
      </p>
      <form [formGroup]="unsubscribeForm" id="unsubscribe-form">
        <div>
          <p-checkbox id="offers-checkbox" formControlName="Offers" [binary]="true" inputId="offers"></p-checkbox>
          <label for="offers">Offers</label>
        </div>
        <div>
          <p-checkbox id="receipts-checkbox" formControlName="Receipts" [binary]="true" inputId="receipts"></p-checkbox>
          <label for="receipts">Receipts</label>
        </div>
        <div>
          <p-checkbox id="updates-checkbox" formControlName="Updates" [binary]="true" inputId="updates"></p-checkbox>
          <label for="updates">Weekly Updates</label>
        </div>
      </form>
      <div>
        <button
          id="subscription-update-button"
          pButton
          pRipple
          label="Update"
          type="button"
          class="p-button-success"
          (click)="updateSubscriptions()"
          [disabled]="unsubscribeForm.pristine"
        ></button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'complete'">
    <div class="status-padding">Your subscriptions have been updated</div>
  </ng-container>
  <ng-container *ngSwitchCase="'error'">
    <div class="status-padding">There was an error with updating your subscriptions</div>
  </ng-container>
</ng-container>
