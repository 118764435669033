import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LoaderComponent } from './loader/loader.component';
import { LinksFooterComponent } from './links-footer/links-footer.component';

@NgModule({
  imports: [TranslateModule, CommonModule, LinksFooterComponent],
  declarations: [LoaderComponent],
  exports: [LoaderComponent, LinksFooterComponent],
})
export class SharedModule {}
